import React from 'react'
import { Heading, Box, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default () => (
    <Box sx={styles.wrapper}>
      <center><h2><a href="https://twitter.com/shakeshoutout">shake shoutout</a></h2>The top three domains won over the last 24 hours on the Handshake network.<br /><br />🏆 https://wahm/<br />3,500.00 HNS<br />0.02 ETH<br />$82.14<br /><br />🥈 https://chinabet/<br />3,500.00 HNS<br />0.02 ETH<br />$82.14<br /><br />🥉 https://namefidao/<br />777.00 HNS<br />0.00 ETH<br />$18.24<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/nbshoutout">nb shoutout</a></h2>The top three domains bought over the last 24 hours in the Namebase marketplace.<br /><br />🏆 https://met/<br />10,000.00 HNS<br />0.06 ETH<br />$231.41<br /><br />🥈 https://bitcoinacceptedhere/<br />1,305.00 HNS<br />0.01 ETH<br />$30.20<br /><br />🥉 https://ernest/<br />1,111.00 HNS<br />0.01 ETH<br />$25.71<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shake_supply">shake supply</a></h2>The circulating HNS supply against the maximum supply.<br /><br />[ ▉▉▉▉▉▎▁▁▁▁▁▁▁▁▁▁▁ ] 31.50%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakesplit">shake split</a></h2>The progress made toward the next Handshake halving.<br /><br />[ ▉▉▉▉▉▉▉▉▌▁▁▁▁▁▁▁▁ ] 50.3%<br /><br /><br /><br /><br /><br /><h2><a href="https://twitter.com/shakefoss">shake foss</a></h2>The claimed HNS against the total airdrop to FOSS developers. And the new and total claims since the last tweet.<br /><br />[ ▍▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁▁ ] 2.91% (New: 0 Total: 6541)<br /><br /><br /><br /><br /><br /></center>
		</Box>
	)